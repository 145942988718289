import React, { useState, useEffect } from 'react';
import styles from './quoting.module.css';
import SerializeSlateToHtml from './RichEditorSlateJS/SerializeSlateToHtml'



function Quote({ onBackClick, onBookClick, onRestartClick, first, last, session, refreshQuote }) {

    const [quoteData, setQuoteData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!refreshQuote) return;

        const fetchQuoteData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}quote/live?session=${session}`);
                if (!response.ok) {
                    return null;
                }
                const data = await response.json();
                setQuoteData(data);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
                setIsLoading(false); 
            }
        };

        fetchQuoteData();
    }, [session, refreshQuote]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!quoteData) {
        return null;
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        }).format(date);
    };

    const formatTime = (timeString) => {
        const [hours, minutes] = timeString.split(':');
        const date = new Date();
        date.setHours(hours, minutes);

        return new Intl.DateTimeFormat('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        }).format(date);
    };

    const getTimeDifference = (startTime, endTime) => {
        // Convert the time strings to Date objects
        const start = new Date(`1970-01-01T${startTime}Z`);
        const end = new Date(`1970-01-01T${endTime}Z`);

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = Math.abs(end - start);

        // Convert milliseconds to hours and minutes
        const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));
        const hours = Math.floor(differenceInMinutes / 60);
        const minutes = differenceInMinutes % 60;

        return { hours, minutes };
    };

    const displayTimeDifference = (startTime, endTime) => {
        const { hours, minutes } = getTimeDifference(startTime, endTime);
        return `${hours} hour${hours !== 1 ? 's' : ''} and ${minutes} minute${minutes !== 1 ? 's' : ''}`;
    }

    let quoteTotal = 0;

    return (
        <>
            <div>
                <h1>Instant Quote Confirmation</h1>
                <hr />
                <h3>Thank you for submitting a quote request.</h3>

                <div className={styles.quoteContainer}>
                    <h1>Custom Price Quote for {first + " " + last}</h1>
                    <p>To change any options, please click the <strong>Edit Quote</strong> button below.</p>

                    <div className={styles.clientInfo}>
                        <div className={styles.infoRow}>
                            <div className={styles.infoLabel}><p><strong>Name:</strong></p></div>
                            <div className={styles.infoDetail}><p>{ quoteData.contact.FirstName + " " + quoteData.contact.LastName  }</p></div>
                        </div>
                        <div className={styles.infoRow}>
                            <div className={styles.infoLabel}><p><strong>Email:</strong></p></div>
                            <div className={styles.infoDetail}><p><a href={"mailto:" + quoteData.contact.Email}> {quoteData.contact.Email}</a></p></div>
                        </div>
                        <div className={styles.infoRow}>
                            <div className={styles.infoLabel}><p><strong>Phone:</strong></p></div>
                            <div className={styles.infoDetail}><p>{quoteData.contact.Phone}</p></div>
                        </div>
                        <div className={styles.infoRow}>
                            <div className={styles.infoLabel}><p><strong>Event Type:</strong></p></div>
                            <div className={styles.infoDetail}><p>{quoteData.event_type_name}</p></div>
                        </div>
                        <div className={styles.infoRow}>
                            <div className={styles.infoLabel}><p><strong>Event Date:</strong></p></div>
                            <div className={styles.infoDetail}><p>{formatDate(quoteData.eventDate)}</p></div>
                        </div>
                        <div className={styles.infoRow}>
                            <div className={styles.infoLabel}><p><strong>Event Start:</strong></p></div>
                            <div className={styles.infoDetail}><p>{formatTime(quoteData.startTime)}</p></div>
                        </div>
                        <div className={styles.infoRow}>
                            <div className={styles.infoLabel}><p><strong>Event End:</strong></p></div>
                            <div className={styles.infoDetail}><p>{formatTime(quoteData.endTime)}</p></div>
                        </div>

                        <div className={styles.infoRow}>
                            <div className={styles.infoLabel}><p><strong>Referred by:</strong></p></div>
                            <div className={styles.infoDetail}><p>{quoteData.referred_by }</p></div>
                        </div>
                    </div>

                    {quoteData.packages.map((packageData, index) => {

                        let service = quoteData.services.find((service) => service.id === packageData.fk_service);
                        let { hours, minutes } = getTimeDifference(quoteData.startTime, quoteData.endTime);
                        // convert hours and minutes to decimal hours
                        let totalHours = hours + (minutes / 60);
                        let chargeableHours = totalHours - packageData.included_hours;
                        let additionalHoursCost = parseFloat(chargeableHours > 0 ? chargeableHours * packageData.hourly_cost : 0).toFixed(2);

                        let enhancementCosts = quoteData.enhancements.filter((enhancement) => enhancement.fk_service === packageData.fk_service).reduce((total, enhancement) => total + enhancement.base_cost, 0);
                        let totalCost = parseFloat(packageData.base_cost) + parseFloat(additionalHoursCost) + parseFloat(enhancementCosts);
                        let packagesAndEnhancementsTotal = parseFloat(totalCost).toFixed(2);

                        quoteTotal += parseFloat(packagesAndEnhancementsTotal);
                        
                        return (
                            <div className={styles.packageInfo}>
                                <h2>{service.service} Package Information</h2>
                                <div className={styles.imageContainer600x400}>
                                    <img src={process.env.REACT_APP_STORAGE_BASE_URL + service.image} alt={packageData.service_name} style={{ width: '100%', height: 'auto' }} />
                                </div>
                                <p className={styles.PackageName}>{packageData.package_name}</p>
                                <p>{service.description}</p>
                                <div className={styles.packageFeaturesTitle}>Package Features</div>
                                <div className={styles.packageFeatures}><SerializeSlateToHtml value={packageData.description} /></div>
                                
                                <div className={styles.infoRow}>
                                    <div className={styles.infoLabel}><p><strong>Total Time Needed:</strong></p></div>
                                    <div className={styles.infoDetail}><p>{displayTimeDifference(quoteData.startTime, quoteData.endTime) }</p></div>
                                </div>
                                <div className={styles.infoRow}>
                                    <div className={styles.infoLabel}><p><strong>Package Selected:</strong></p></div>
                                    <div className={styles.infoDetail}><p>{packageData.package_name }</p></div>
                                </div>
                                <div className={styles.infoRow}>
                                    <div className={styles.infoLabel}><p><strong>Time Included with Package:</strong></p></div>
                                    <div className={styles.infoDetail}><p>{packageData.included_hours} Hours</p></div>
                                </div>
                                <div className={styles.infoRow}>
                                    <div className={styles.infoLabel}><p><strong>Price for {packageData.package_name } Package:</strong></p></div>
                                    <div className={styles.infoDetail}><p>${packageData.base_cost}</p></div>
                                </div>
                                <div className={styles.infoRow}>
                                    <div className={styles.infoLabel}><p><strong>Additional cost for {chargeableHours} hours:</strong></p></div>
                                    <div className={styles.infoDetail}><p>${additionalHoursCost}</p></div>
                                </div>
                                <div className={styles.infoRow}>
                                    <div className={styles.infoLabel}><p><strong>Package Total:</strong></p></div>
                                    <div className={styles.infoDetail}><p>${parseFloat(packageData.base_cost + additionalHoursCost).toFixed(2)}</p></div>
                                </div>


                                <div className={styles.infoRow}>
                                    <div className={styles.infoLabel}><p><strong>Enhancements:</strong></p></div>
                                    <div className={styles.infoDetail}><p>
                                        <ul className={styles.enhancements}>
                                            {quoteData.enhancements.filter((enhancement) => enhancement.fk_service === packageData.fk_service).map((enhancement) => (
                                                <li>{enhancement.enhancement_name} : ${enhancement.base_cost}</li>
                                            ))}
                                            
                                        </ul>
                                    </p></div>
                                </div>
                                <div className={styles.infoRow}>
                                    <div className={styles.infoLabel}><p><strong>Enhancements Total:</strong></p></div>
                                    <div className={styles.infoDetail}><p>${parseFloat(enhancementCosts).toFixed(2)}</p></div>
                                </div>

                                <div className={styles.infoRow}>
                                    <div className={styles.infoLabel}><p><strong>Total of Package with Enhancements:</strong></p></div>
                                    <div className={styles.infoDetail}><p>${packagesAndEnhancementsTotal}</p></div>
                                </div>

                                


                            </div>
                        );
                    })}

                    

                    <div className={styles.totalInfo}>
                        <div className={styles.infoRow}>
                            <div className={styles.infoLabel}><p><strong>TOTAL:</strong></p></div>
                            <div className={styles.infoDetail}><p>${parseFloat(quoteTotal).toFixed(2)}</p></div>
                        </div>
                        
                    </div>

                    <button style={{ backgroundColor: "#999" }} className={styles.fielddivided} onClick={onBackClick}>&gt; Edit Quote &lt;</button>

                </div>

                <div style={{ maxWidth: "800px", margin: "20px auto" }}>
                    <button style={{ backgroundColor: "#999" }} className={styles.fielddivided} onClick={onRestartClick}>&lt;&lt; Start Over and Generate New Quote. </button>
                    <button className={styles.fielddivided} onClick={() => onBookClick(quoteTotal.toFixed(2))}>Confirm Quote & Proceed to Contract &gt;&gt;</button>
                    <div className={styles.spacer} />
                    <div className={styles.spacer} />
                </div>
            </div>

        </>

    );
}

export default Quote;