import React, { useState, useEffect } from 'react';
import styles from './formStyling.module.css';
import SerializeSlateToHtml from './RichEditorSlateJS/SerializeSlateToHtml'



function PackageList({ enabledPackages, packagesData, onPackageClick, handleImageClick }) {

	const [PackagesList, setPackagesList] = useState("");



	useEffect(() => {

		if (!packagesData.length) {
			setPackagesList("No packages available for the selected event type.");
			return;
		}

		setPackagesList( <div>{ JSON.stringify(packagesData) }</div>);
		

		let list = packagesData.map((item) => {

			var videoSection = "";

			if (item.video.length > 0) {
				videoSection = <img src="/images/play.png" alt="playbutton" className={styles.playButton} onClick={(event) => handleImageClick(event, item.video)} />;
			}

			return (
				<li key={item.id} className={`${styles.ListItem} ${(enabledPackages.includes(item.id)) ? styles.selected : ''}`} onClick={() => onPackageClick(item.id)}>

					<div style= {{ padding: "10px" } }>
						<span style={{
							backgroundImage: "url('" + process.env.REACT_APP_STORAGE_BASE_URL + item.image + "')", height: "160px", width: "240px", backgroundSize: "cover",
							color: "white",
							fontSize: "20px",
							textAlign: "center",
						}}
							className={styles.optionName}>{item.name}{videoSection}</span>

						<span><ul style={{ paddingLeft: "20px", marginTop: "30px" }}>
							<pre style={{ whiteSpace: 'pre-wrap', fontFamily: 'inherit' }}><SerializeSlateToHtml value={item.description} /></pre>

							
						</ul></span>
					</div>

				</li>
			);
		})
		setPackagesList(list);
		

	}, [enabledPackages, onPackageClick, packagesData]);

	return PackagesList;


}

export default PackageList;