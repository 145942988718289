import React, { useEffect, useState } from 'react';
import styles from './formStyling.module.css';
function ChooseServices({ onNextClick, onBackClick,  services, toggleServiceClick, enabledServices, startingService }) {

	const [OptionsList, setOptionsList] = useState([]);

	console.log(startingService);
	var startingServiceObj = services.find((obj) => obj.id === startingService);

	useEffect(() => {

		let options = services.map((obj) => {

			if (obj.id === startingService) return ''; // hide the starting service (assuming we selected it on the site)
			if (obj.packages.length === 0) return '';
			
			return (

				<li key={obj.id} className={`${styles.ListItem} ${styles.imageContainer240x160}  ${(enabledServices.includes(obj.id)) ? styles.selected : ''}`}>

					<img src={process.env.REACT_APP_STORAGE_BASE_URL + obj.image} alt={obj.service} className={`${styles.serviceImage}`} onClick={toggleServiceClick} value={obj.id} />

				</li>
			);



		});

		setOptionsList(options);

	}, [enabledServices, services, toggleServiceClick, startingService])

	console.log(startingServiceObj);
	if (typeof startingServiceObj === 'undefined') return '';


	return (
		<div className={styles.wrap} >
			
			<h1>Choose Services</h1>

			<div style={{ width: "100%" }}>
				<div className={`${styles.centeredRow} ${styles.ListItem} ${styles.selected}`}>

					<img src={process.env.REACT_APP_STORAGE_BASE_URL + startingServiceObj.image} alt={startingServiceObj.service} className={styles.serviceImage} />
				</div>
			</div>

			<h3>What additional services are you looking for? (multiple allowed): </h3>
			<hr />

			<ul className={styles.List}>
				{OptionsList}

			</ul>
			<ul>

				<li>
					
					<input className={`${styles.buttondivided} ${styles.backButton}`} type="button" value="<< Go Back" onClick={onBackClick} />
					<input className={styles.buttondivided} type="button" value="Next >>" onClick={onNextClick} />
				</li>
			</ul>
		</div>
	);
}

export default ChooseServices;